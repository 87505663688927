

//Mobile Navigation
.mbNavigation-container {
  @include screen-dimension;
  z-index: 3;
  position: fixed;
  // overflow-y:hidden;
  top: 0;
  left: 0;
  // background-color: #f7ede2;
  width: 100vw;
  transition: transform 0.5s;
  @include flex-c-c-s;
  //Laptop
  @media only screen and (min-width: 1280px) {
    display: none;
  }
  .closeNav-container {
    position: absolute;
    top: 0;
    right: 0.5em;
    padding: 2em;

    .close-icon {
      font-size: $smH2;
      color: inherit;
      //Ipad Mini
      @media only screen and (min-width: 768px) {
        font-size: $mdH2 + 0.5rem;
      }
    }
  }
  .openNav-container {
    @extend .closeNav-container;
    top: 1.5rem;
    right: -23%;
    // Iphone 6/7/8/X Plus
    @media only screen and (min-width: 375px) {
      right: -19%;
    }
    //Ipad Mini
    @media only screen and (min-width: 768px) {
      right: -12%;
    }
    .open-icon {
      @extend .close-icon;
      padding-left: 0.5em;
    }
  }
  .nav-logo-container {
    padding: 2em;
    .nav-logo {
      width: 5.5rem;
      border-radius: 50%;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      //Ipad Mini
      @media only screen and (min-width: 768px) {
        width: 7.5rem;
      }
    }
  }
  .active-link-red {
    background-color: crimson;
    color: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  .active-link-white {
    background-color: #f7ede2;
    color: black;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  .nav-link {
    width: 100%;
    font-size: $smH2;
    padding: 1em 0em;
    text-align: center;
    //Ipad Mini
    @media only screen and (min-width: 768px) {
      font-size: $mdH2;
    }
  }
}

//Desktop Navigation
.dtNavigation-container {
  display: none;
  background-color: inherit;
  color: inherit;
  border-color: inherit;
  @media only screen and (min-width: 1280px) {
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    @include flex-r-c-c;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    position: fixed;
    z-index: 3;
  }
  .nav-logo-container {
    position: absolute;
    left: 8vw;
    top: 0.8rem;
    .nav-logo {
      width: 3.5rem;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      border-radius: 50%;
    }
  }
  .nav-link {
    color: inherit;
    margin: 1.5em 2.5em 1em 2em;
    font-size: $lgH2 - 0.5rem;
  }
  .active-link {
    color: inherit;
    border-color: inherit;
    border-style: solid;
    border-width: 1px;
    border-top: transparent;
    border-left: transparent;
    border-right: transparent;
  }
  .update {
    width: 100%;
    text-align: center;
    background-color: #19314d;
    color: white;
    margin-top: 0.5rem;
    padding: 0.5rem 0;
    font-size: 1.2rem;
  }
}
// Iphone 6/7/8/X Plus
@media only screen and (min-width: 375px) {
}
//Ipad Mini
@media only screen and (min-width: 768px) {
}
//Ipad Pro 10.5"
@media only screen and (min-width: 834px) {
}
//Ipad Pro 12.5"
@media only screen and (min-width: 1024px) {
}
//Laptop
@media only screen and (min-width: 1280px) {
}
