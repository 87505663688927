.home-container {
  @include screen-dimension;
  @include flex-c-c-s;
  color: white;
  border-color: white;
  background-color: #d62828;
  position: relative;
  //Laptop
  @media only screen and (min-width: 1280px) {
    min-height: 100vh;
  }
  .animation-container {
    // @extend .home-container;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:100%;
    animation: slide-up 0.5s;
  }
  .logo-container {
    @include flex-c-c-c;
    // margin-top: 5em;
    //Ipad Mini
    @media only screen and (min-width: 768px) {
      font-size: $mdH1;
      // margin-top: 2em;
    }
    //Ipad Pro 12.5"
    @media only screen and (min-width: 1024px) {
      // margin-top: 3em;
    }
    //Laptop
    @media only screen and (min-width: 1280px) {
      // margin-top: 3em;
    }
    .logo-icon {
      width: $smLogo;
      margin-bottom: 2rem;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      border-radius: 50%;
      //Ipad Mini
      @media only screen and (min-width: 768px) {
        width: $mdLogo - 1rem;
      }
      //Ipad Pro 12.5"
      @media only screen and (min-width: 1024px) {
        width: $mdLogo + 1.5rem;
      }
      //Laptop
      @media only screen and (min-width: 1280px) {
        width: $lgLogo;
      }
    }
    .logo-name {
      //Ipad Mini
      @media only screen and (min-width: 768px) {
        font-size: $mdH1;
      }
      //Ipad Pro 12.5"
      @media only screen and (min-width: 1024px) {
        font-size: $mdH1 + 1rem;
      }
      //Laptop
      @media only screen and (min-width: 1280px) {
        font-size: $lgH1;
      }
    }
    .logo-info {
      @media only screen and (min-width: 768px) {
        font-size: $mdP;
      }
    }
  }
  .menu-btn-container {
    margin-top: 30px;
    // Iphone 6/7/8/X Plus
    @media only screen and (min-width: 375px) {
      margin-top: 50px;
    }
    //Ipad Mini
    @media only screen and (min-width: 768px) {
      margin-top: 50px;
    }
    //Laptop
    @media only screen and (min-width: 1280px) {
      margin-top: 50px;
    }
    .menu-btn {
      border: solid 1px white;
      color: white;
      font-size: $smP;
      letter-spacing: 3px;
      padding: 0.8rem 2.5em;
      border-radius: 0.2em;
      transition: transform 1.2s;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      .nav-link {
        width: 100%;
        height: 100%;
      }
      &:hover {
        transform: scale(1.09);
      }
      //Ipad Mini
      @media only screen and (min-width: 768px) {
        font-size: $mdH2;
      }
      //Ipad Pro 12.5"
      @media only screen and (min-width: 1024px) {
        font-size: $mdH2 + 0.5rem;
      }
      //Laptop
      @media only screen and (min-width: 1280px) {
        font-size: $lgP + 0.2rem;
      }
    }
  }
}
.footer-home {
  color: white;
  background-color: #d62828;
}

.mobile-home-update {
  background-color: #19314d;
  width: 100%;
  text-align: center;
  padding: 0.5rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  z-index: 2;
}
@media only screen and (min-width: 1280px) {
  // .mobile-home-update {
  //   display: none;
  // }
}

// Iphone 6/7/8/X Plus
@media only screen and (min-width: 375px) {
}
//Ipad Mini
@media only screen and (min-width: 768px) {
}
//Ipad Pro 10.5"
@media only screen and (min-width: 834px) {
}
//Ipad Pro 12.5"
@media only screen and (min-width: 1024px) {
}
//Laptop
@media only screen and (min-width: 1280px) {
}
