.footer-container {
  height: 100%;
  width: 100vw;
  overflow-x: hidden;
  position: relative;
  background-color:inherit;
  animation: slide-in 0.5s;

  .footer-top {
    @include flex-c-se-c;
    text-align: center;
    height: 100vh;
    color:black;
    @media only screen and (min-width:500px) {
      min-height: 600px;
    }
    @media only screen and (min-width:700px) {
      height: 30vh;
      min-height: 750px;
    }
    @media only screen and (min-width:850px) {
      height: 30vh;
      min-height: 850px;
    }
    @media only screen and (min-width:1300px) {
      @include flex-r-se-c;
      flex-direction: row;
      height: 50vh;
      min-height: 600px;
    }
    h3{
      padding-top: 4rem;
    }
    p, a{
      font-size: 1.4rem;
    }
    // border:solid 2px black;
    .footer-title {
      position: relative;
      bottom: 2em;
      font-size: $subTitle300Px;
      @media only screen and (min-width:700px) {
        font-size: $title700Px + 0.5rem;
      }
    }
    .contact {
      @include flex-c-c-c;
      box-shadow: var(--shadow);
      height: 100%;
      width: 100%;
      background-color: #f6f6f6;
      @media only screen and (min-width:700px) {
        font-size: $text700Px;
      }
      .phone1{
        color:rgb(0, 60, 255);
      }
      .phone2{
        @extend .phone1;
        padding-top:1em;
      }
    }
    .location {
      @extend .contact;
      background-color: #faf9f9;
    }
    .hours {
      @extend .contact;
    }
  }
  .footer-bottom {
    @include flex-c-se-c;
    align-items: center;
    padding: 1em 0;
    color: inherit;
    background-color: inherit;
    @media only screen and (min-width:700px) {
      @include flex-r-se-c;
      flex-direction: row;
      .developer {
        margin-bottom: 1em;
      }
      .photographer {
        @extend .developer;
      }
    }
    .credit-1 {
      text-align: center;
      margin-bottom: 1em;
      @media only screen and (min-width:700px) {
        margin-bottom: 0;
      }
    }
    .credit-2 {
      @extend .credit-1;
    }
  }
}
