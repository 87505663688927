.takeout-container {
  @include flex-c-c;
  text-align: center;
  height: 100vh;
  background-color: #d62828;
  color:white;
  @media only screen and (min-width:500px) {
    min-height: 600px;
  }
  @media only screen and (min-width:700px) {
    min-height: 900px;
  }
  @media only screen and (min-width:850px) {
    min-height: 1100px;
  }
  @media only screen and (min-width:1280px) {
    min-height: 600px;
  }
  .takeout-title {
    margin-top: 4em;
    color: white;
    animation: slide-up 0.5s;

    @media only screen and (min-width:700px) {
      font-size: $title700Px;
      margin-top: 5em;
    }
  }
  .takeout-body {
    animation: slide-up 0.5s;
    color:black;
    @include flex-c-se-c;
    height: 100%;
    @media only screen and (min-width:1280px) {
      @include flex-r-se-c;
      flex-direction: row;
      // border: solid 2px black;
      height: auto;
      margin: auto;
    }
    .takeout-info {
      @media only screen and (min-width:1280px) {
        @include flex-c-c-c;
        width: 100%;
        order: 2;
      }
      .discount-container {
        display: flex;
        width: $imgWidth300Px;
        height: $imgWidth300Px / (200/63);
        border-radius: var(--border-radius);
        overflow: hidden;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        @media only screen and (min-width:370px) {
          width: $imgWidth375Px;
          height: $imgWidth375Px / (200/63);
        }
        @media only screen and (min-width:700px) {
          width: $imgWidth700Px;
          height: $imgWidth700Px / (200/63);
        }
        @media only screen and (min-width:830px) {
          width: $imgWidth834Px;
          height: $imgWidth834Px / (200/63);
        }
        @media only screen and (min-width:1280px) {
          width: $imgWidth834Px;
          height: 50%;
        }
        .discount-icon {
          @include flex-c-c-c;
          color: white;
          font-size: $icon300Px;
          width: 25%;
          background-color: #f32e2e;
          @media only screen and (min-width:700px) {
            font-size: $icon700Px;
          }
          @media only screen and (min-width:830px) {
            font-size: $icon834Px;
          }
        }
        .discount-info {
          padding: 0.5em 0;
          @include flex-c-c-c;
          width: 100%;
          background-color: #f7ede2;
          @media only screen and (min-width:700px) {
            font-size: $text700Px;
          }
          .discount-title {
            font-weight: bold;
          }
        }
      }
      .delivery-container {
        @extend .discount-container;
        margin-top: 1em;
        .delivery-icon {
          @extend .discount-icon;
        }
        .delivery-info {
          text-align: center;
          @extend .discount-info;
          .delivery-title {
            @extend .discount-title;
          }
        }
      }
    }

    .takeout-images {
      @include flex-c-se-c;
      position: relative;
      @media only screen and (min-width: 1280px) {
        margin-right: 1em;
      }
      .carousel-container {
        height: $imgWidth300Px / (4/2.4);
        width: $imgWidth300Px;
        overflow: hidden;
        position: relative;
        margin: 0 auto;
        border-radius: var(--border-radius);
        @media only screen and (min-width:370px) {
          width: $imgWidth375Px;
          height: $imgWidth375Px / (4/2.4);
        }
        @media only screen and (min-width:700px) {
          width: $imgWidth700Px;
          height: $imgWidth700Px / (4/2.4);
        }
        @media only screen and (min-width:830px) {
          width: $imgWidth834Px;
          height: $imgWidth834Px / (4/2.4);
        }
        .images {
          @include flex-r-c-c;
          position: absolute;
          transition: transform 0.3s linear;
          .image-container {
            position: relative;
            border-radius: var(--border-radius);
            overflow: hidden;
            .image {
              width: $imgWidth300Px;
              object-fit: contain;
              position: relative;
              bottom: 1em;
              @media only screen and (min-width:370px) {
                width: $imgWidth375Px;
              }
              @media only screen and (min-width:700px) {
                width: $imgWidth700Px;
              }
              @media only screen and (min-width:830px) {
                width: $imgWidth834Px;
              }
            }
            .image-name {
              color: white;
              position: absolute;
              top: 0;
              width: 100%;
              background-color: rgba(0, 0, 0, 0.23);
              @media only screen and (min-width:700px) {
                font-size: $icon700Px;
              }
            }
          }
        }
      }
      .carousel-controls {
        @include flex-r-sb-c;
        margin: auto;
        margin-top: 1em;
        width: 95%;
        @media only screen and (min-width: 1280px) {
          @include flex-r-sb-c;
          position: absolute;
          bottom: -5em;
          left: 50%;
          transform: translateX(-50%);
        }
        .left-arrow {
          font-size: $carouselArrow300Px;
          padding: 0.2em 0.4em;
          @include flex-r-c-c;
          // Iphone 6/7/8/X Plus
          @media only screen and (min-width: 375px) {
            padding: 0.4em 0.7em;
          }
          //Ipad Mini
          @media only screen and (min-width: 768px) {
            font-size: $carouselArrow300Px + 1em;
          }
        }
        .right-arrow {
          @extend .left-arrow;
        }
        .carousel-dots {
          @include flex-r-c-c;
          .carousel-dot {
            margin: 0 0.2em;
            width: $carouselDot300Px;
            height: $carouselDot300Px;
            background-color: black;
            border-radius: 50%;
            //Ipad Mini
            @media only screen and (min-width: 768px) {
              width: $carouselDot300Px + 0.2em;
              height: $carouselDot300Px + 0.2em;
            }
          }
        }
      }
    }
  }
}
.info-footer{
  color:white;
  background-color: #d62828;
}