.buffet-container {
  @include flex-c-c;
  height: 100vh;
  background-color: #f7ede2;
  @media only screen and (min-width:500px) {
    min-height: 600px;
  }
  @media only screen and (min-width:700px) {
    min-height: 900px;
  }
  @media only screen and (min-width:850px) {
    min-height: 1100px;
  }
  @media only screen and (min-width:1280px) {
    min-height: 600px;
  }
  .buffet-title {
    margin-top: 4em;
    animation: slide-in 0.5s;

    @media only screen and (min-width:700px) {
      font-size: $title700Px;
      margin-top: 5em;
    }
  }
  .buffet-body {
    animation: slide-in 0.5s;
    @include flex-c-se-c;
    height: 100%;
    @media only screen and (min-width:1280px) {
      @include flex-r-se-c;
      flex-direction: row;
      // border: solid 2px black;
      height: auto;
      margin: auto;
    }
    .buffet-info {
      @media only screen and (min-width:1280px) {
        @include flex-c-c-c;
        width: 100%;
        margin-right: 1em;
      }
      .price-container {
        display: flex;
        width: $imgWidth300Px;
        min-height: $imgWidth300Px / (200/63);
        height:auto;
        border-radius: var(--border-radius);
        overflow: hidden;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        @media only screen and (min-width:370px) {
          width: $imgWidth375Px;
          min-height: $imgWidth375Px / (200/63);
          height: auto;
        }
        @media only screen and (min-width:700px) {
          width: $imgWidth700Px;
          min-height: $imgWidth700Px / (200/63);
          height: auto;
        }
        @media only screen and (min-width:830px) {
          width: $imgWidth834Px;
          min-height: $imgWidth834Px / (200/63);
          height: auto;
        }
        @media only screen and (min-width:1280px) {
          width: $imgWidth834Px;
          height: 50%;
        }
        .price-icon {
          @include flex-c-c-c;
          font-size: $icon300Px;
          width: 25%;
          @media only screen and (min-width:700px) {
            font-size: $icon700Px;
          }
          @media only screen and (min-width:830px) {
            font-size: $icon834Px;
          }
        }
        .price-info {
          color: white;
          padding: 0.5em 0;
          @include flex-c-c-c;
          width: 100%;
          background-color: #d62828;
          @media only screen and (min-width:700px) {
            font-size: $text700Px;
          }
          .lunch {
            @include flex-c-c-c;
            .lunch-price {
              font-weight: bold;
            }
          }
          .dinner {
            @extend .lunch;
            margin-top: 0.5em;
            @media only screen and (min-width:700px) {
              margin-top: 1.5em;
            }
            .dinner-price {
              @extend .lunch-price;
            }
          }
        }
      }
      .birthday-container {
        @extend .price-container;
        margin-top: 1em;
        .birthday-icon {
          @extend .price-icon;
        }
        .birthday-info {
          text-align: center;
          @extend .price-info;
          .birthday-title {
            font-weight: bold;
          }
        }
      }
    }

    .buffet-images {
      @include flex-c-se-c;
      position: relative;
      .carousel-container {
        height: $imgWidth300Px / (4/2.4);
        width: $imgWidth300Px;
        overflow: hidden;
        position: relative;
        margin: 0 auto;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        border-radius: var(--border-radius);
        @media only screen and (min-width:370px) {
          width: $imgWidth375Px;
          height: $imgWidth375Px / (4/2.4);
        }
        @media only screen and (min-width:700px) {
          width: $imgWidth700Px;
          height: $imgWidth700Px / (4/2.4);
        }
        @media only screen and (min-width:830px) {
          width: $imgWidth834Px;
          height: $imgWidth834Px / (4/2.4);
        }
        .images {
          @include flex-r-c-c;
          position: absolute;
          transition: transform 0.3s linear;
          .image {
            // width: $imgWidth300Px;
            height: 100%;
            object-fit: cover;
            @media only screen and (min-width:370px) {
              width: $imgWidth375Px;
            }
            @media only screen and (min-width:700px) {
              width: $imgWidth700Px;
            }
            @media only screen and (min-width:830px) {
              width: $imgWidth834Px;
            }
          }
        }
      }
      .carousel-controls {
        @include flex-r-sb-c;
        margin: auto;
        margin-top: 1em;
        width: 95%;
        @media only screen and (min-width: 1280px) {
          @include flex-r-sb-c;
          position: absolute;
          bottom: -6em;
          left: 50%;
          transform: translateX(-50%);
        }
        .left-arrow {
          font-size: $carouselArrow300Px;
          padding: 0 1em;
          //Ipad Mini
          @media only screen and (min-width: 768px) {
            font-size: $mdH2 + 1rem;
            padding: 0.5em;
          }
        }
        .right-arrow {
          @extend .left-arrow;
        }
        .carousel-dots {
          @include flex-r-c-c;
          .carousel-dot {
            margin: 0 0.5em;
            width: $carouselDot300Px;
            height: $carouselDot300Px;
            background-color: black;
            border-radius: 50%;
            @media only screen and (min-width: 768px) {
              width: $carouselDot300Px + 0.5em;
              height: $carouselDot300Px + 0.5em;
            }
          }
        }
      }
    }
  }
}
.footer-buffet {
  color: black;
  background-color: #f7ede2;
}
