body {
  --shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  --border-radius: 10px;
}

// .holiday-info {
//   display: none;
//   background-color: #588157;
//   padding: 2em 0 2em 0;
//   color: white;
//   text-align: center;
//   max-width: 1200px;
//   width: 100%;
//   margin-top: 50px;
//   border-radius: var(--border-radius);

//   h2 {
//     padding: 0.5em 0 0.5em 0;
//     @media only screen and (min-width: 850px) {
//       font-size: 2rem;
//     }
//   }
//   p {
//     @media only screen and (min-width: 850px) {
//       font-size: 1.3rem;
//     }
//     padding: 1em 0 1em 0;
//   }
//   li {
//     @media only screen and (min-width: 850px) {
//       font-size: 1.3rem;
//     }
//   }
// }

.menu-container {
  .holiday-info {
    margin-top: 2em;
    background-color: #73a942;
    padding: 2em 0 2em 0;
    color: white;
    text-align: center;
    border-radius: var(--border-radius);
    width: 100%;
    h2 {
      padding: 0.5em 0 0.5em 0;
      @media only screen and (min-width: 850px) {
        font-size: 2rem;
      }
    }
    p {
      @media only screen and (min-width: 850px) {
        font-size: 1.3rem;
      }
      padding: 1em 0 1em 0;
    }
    li {
      @media only screen and (min-width: 850px) {
        font-size: 1.3rem;
      }
    }

    width: $imgWidth300Px;
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    @media only screen and (min-width: 370px) {
      width: $imgWidth375Px;
    }
    @media only screen and (min-width: 700px) {
      width: $imgWidth700Px;
      font-size: $text700Px;
    }
    @media only screen and (min-width: 830px) {
      width: $imgWidth834Px;
    }
    @media only screen and (min-width: 1280px) {
      max-width: 1200px;
      width: 100%;
    }
  }

  @include flex-c-c;
  min-height: 100vh;
  padding-bottom: 3em;
  background-color: #f7ede2;
  // font-weight: bold;
  @media only screen and (min-width: 500px) {
    min-height: 600px;
  }
  @media only screen and (min-width: 700px) {
    min-height: 900px;
  }
  @media only screen and (min-width: 850px) {
    min-height: 1100px;
  }
  @media only screen and (min-width: 1280px) {
    min-height: 600px;
  }
  .menu-title {
    margin-top: 5em;
    @media only screen and (min-width: 700px) {
      font-size: $title700Px;
      margin: 5em 0 2em 0;
    }
  }
  .content-container {
    @extend .menu-container;
    width: 100%;
    animation: slide-in 0.5s;
    margin-top: 30px;
  }
  .family-title {
    @extend .menu-title;
  }
  .phone-number {
    @include flex-c-c-c;
    color: white;
    background-color: #d62828;
    padding: 1em 0;
    width: $imgWidth300Px;
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    @media only screen and (min-width: 370px) {
      width: $imgWidth375Px;
    }
    @media only screen and (min-width: 700px) {
      width: $imgWidth700Px;
      font-size: $text700Px;
    }
    @media only screen and (min-width: 830px) {
      width: $imgWidth834Px;
    }
    @media only screen and (min-width: 1280px) {
      max-width: 1200px;
      width: 100%;
    }
    a {
      font-weight: bold;
    }
    .phone-logo {
      font-size: 2rem;
    }
    .phone2 {
      padding-top: 1em;
    }
    .call-title {
      margin-bottom: 1em;
      font-weight: bold;
    }
  }
  .disclaimer {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    background: #f7ede2;
    text-align: center;
    width: $imgWidth300Px;
    line-height: 25px;
    padding: 1em;
    margin-top: 2em;
    border-radius: var(--border-radius);
    h2 {
      padding: 1rem 0;
      color: #d62828;
      font-weight: bolder;
      font-family: "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif !important;
    }
    li {
      text-align: left;
      padding: 0.5rem;
      list-style: disc;
      margin-left: 20px;
    }
    @media only screen and (min-width: 370px) {
      width: $imgWidth375Px;
    }
    @media only screen and (min-width: 700px) {
      width: $imgWidth700Px;
      font-size: $text700Px;
    }
    @media only screen and (min-width: 830px) {
      width: $imgWidth834Px;
    }
    @media only screen and (min-width: 1280px) {
      max-width: 1200px;
      width: 100%;
    }
  }
  .disclaimer p {
    padding: 0.5rem 0;
  }
  .combo-container {
    width: $imgWidth300Px;
    margin-top: 2em;
    padding: 1em 10px;
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    @media only screen and (min-width: 370px) {
      width: $imgWidth375Px;
    }
    @media only screen and (min-width: 700px) {
      width: $imgWidth700Px;
      font-size: $text700Px;
    }
    @media only screen and (min-width: 830px) {
      width: $imgWidth834Px;
    }
    @media only screen and (min-width: 1280px) {
      max-width: 1200px;
      width: 100%;
    }
    .combo-header {
      text-align: center;
      margin-bottom: 1em;
      .combo-title {
        font-weight: bold;
        font-size: $sectionTitle300Px;
        @media only screen and (min-width: 700px) {
          font-size: $title700Px;
        }
      }
      .combo-price {
        margin-top: 1em;
      }
    }
    .combo-info {
      display: flex;
      flex-direction: column;
      justify-content: start;
      padding-left: 1rem;
      gap: 10px;

      p {
        display: flex;
        gap: 35px;

        span:nth-child(1) {
          width: 20px;
        }
      }
    }
  }
  .item-container {
    border-radius: var(--border-radius);
    @extend .combo-container;
    .section-title {
      text-align: center;
      font-size: $sectionTitle300Px;
      font-weight: bold;
      margin-bottom: 1em;
      @media only screen and (min-width: 700px) {
        font-size: $title700Px;
      }
    }
    .section-title-3::after {
      display: block;
      content: "(Chow Mein = Chop Suey + Dry Noodles add $1.00)";
      padding: 0 0.5rem;
      font-size: 16px;
      color: #006600;

      @media only screen and (max-width: 700px) {
        font-size: 0.9rem;
      }
    }
    .item-info {
      .name-price {
        @include flex-r-sb-c;
        margin: 0.5em;
      }
      .item-description {
        color: #006600;
        margin: 0.5em 3em 0.5em 0.5em;
        font-weight: bold;
        font-size: 14px;
      }
    }
    .family-name {
      @extend .section-title;
    }
    .family-info {
      text-align: center;
      margin-top: 0.3em;
    }
  }
}

.footer-menu {
  background-color: #f7ede2;
}
